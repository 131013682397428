import React from 'react';
import { Switch, Route } from 'react-router-dom';
import WholesalerCreditReport from './wholesalerCreditReport';
import UploadCredits from './uploadCredits';
import UnmatchedCredits from './unmatchedCredits';
import McKesson from './mcKesson';
import CustomerListView from './customerListView';
import CreditDetail from './creditReport/creditReport';
import OutboundChecks from './outboundChecks';
import OutboundCheckOverview from './outboundCheckOverview';
import ReportsRouter from './reports/reportsRouter';
import SalesCommission from './salesCommission';

const AccountingRouter = props => (
    <Switch>
        <Route exact path="/accounting" component={CustomerListView} />
        <Route exact path="/accounting/wholesaler/credit/report" component={WholesalerCreditReport} />
        <Route exact path="/accounting/wholesaler/credit/report/:id" component={UploadCredits} />
        <Route exact path="/accounting/unmatched/credits" component={UnmatchedCredits} />
        <Route exact path="/accounting/mckesson" component={McKesson} />
        <Route exact path="/accounting/creditdetail" component={CreditDetail} />
        <Route exact path="/accounting/outbound/checks" component={OutboundChecks} />
        <Route exact path="/accounting/outbound/checks/overview" component={OutboundCheckOverview} />
        <Route exact path="/accounting/sales/commission" component={SalesCommission} />
        <Route path="/accounting/report" component={ReportsRouter} />
    </Switch>
)

export default AccountingRouter;