import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';
import moment from 'moment';

class WholesalerCreditReport extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        wholesalerList: null,
        files: null,
        values: this.initValues()
    })

    initValues = () => ({
        filename: '',
        wholesalerId: -1,
        notes: '',
        dateOfReceipt: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getFiles();
        this.getWholesalers();
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    networkCalls = {
        getFiles: {
            func: () => {
                Axios.get('/api/v1/creditmemo/read/all/files', {
                    params: this.authData
                })
                    .then(result => this.setState({ files: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        getWholesalers: {
            func: () => {
                Axios.get('/api/v1/party/wholesaler/read/all/simple', {
                    params: this.authData
                })
                    .then(result => {
                        const wholesalerList = result.data.filter(wh => {
                            const wholesalers = ['amerisourcebergen', 'cardinal', 'mckesson'];
                            const name = wh.name.toLowerCase();
                            return wholesalers.indexOf(name) !== -1;
                        });
                        this.setState({ wholesalerList }, () => {
                            M.FormSelect.init(document.querySelectorAll('select'))
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        uploadFile: {
            func: () => {
                const file = document.getElementById('file').files[0]
                const formData = new FormData();

                formData.append('files[]', file);
                formData.append('_id', this.authData._id);
                formData.append('_session_id', this.authData._session_id);
                formData.append('_token', this.authData._token);
                formData.append('user_timezone', this.authData.user_timezone);
                formData.append('wholesalerId', this.state.values.wholesalerId);
                formData.append('uploaderId', this.employeeData.id);
                formData.append('notes', this.state.values.notes);
                formData.append('dateOfReceipt', this.state.values.dateOfReceipt);

                this.setState({ uploadingFiles: true }, () => {
                    Axios.post('/api/v1/file/upload/wholesalercreditreport', formData, { headers: { 'content-type': 'multipart/form-data' } })
                        .then(result => {
                            M.Modal.getInstance(document.querySelector('#upload-modal')).close();
                            document.getElementById('file').value = '';
                            document.getElementById('file-path').value = '';
                            this.props.dispatch(result.data);
                            this.setState({ values: this.initValues() }, () => {
                                M.textareaAutoResize(document.querySelector('#notes'));
                                M.updateTextFields();
                                M.FormSelect.init(document.querySelector('#wholesalerId'));
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ uploadingFiles: false }))
                })
            },
            type: 'c'
        }
    }

    getFiles = () => this.networkCalls.getFiles();
    getWholesalers = () => this.networkCalls.getWholesalers();
    uploadFile = e => { e?.preventDefault(); this.networkCalls.uploadFile(); }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    openUploadModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#upload-modal')).open();
    }

    uploadCancel = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#upload-modal')).close();
        document.getElementById('file').value = '';
        document.getElementById('file-path').value = '';
        this.setState({ values: this.initValues() }, () => {
            M.textareaAutoResize(document.querySelector('#notes'));
            M.updateTextFields();
            M.FormSelect.init(document.querySelector('#wholesalerId'));
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Wholesaler Credit Reports</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Wholesaler Credit Reports</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m2 offset-m10">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.openUploadModal}>Upload File</a>
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>Upload Date</th>
                            <th>File Name</th>
                            <th>Uploader</th>
                            <th>Wholesaler</th>
                            <th>Status</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.files && this.state.files.map(f => (
                            <tr key={`file-${f.id}`}>
                                <td>{moment(f.dateUploaded).format('MM/DD/YYYY')}</td>
                                <td><Link to={{ pathname: `/accounting/wholesaler/credit/report/${f.id}`, state: { filename: f.filename } }}>{f.filename}</Link></td>
                                <td>{f.uploader.firstName} {f.uploader.lastName}</td>
                                <td>{f.wholesaler.name}</td>
                                <td>{f.status}</td>
                                <td>{f.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div id="upload-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>Upload New Wholesaler Report</h5>
                    </div>
                    <div className="row">
                        <div className="file-field col s12">
                            <div>
                                <div>
                                    <input type="file" id="file" name="file" />
                                    <label htmlFor="file">Browse Files</label>
                                </div>
                                <div className="file-path-wrapper">
                                    <input id="file-path" className="file-path" type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <select id="wholesalerId" onChange={this.onChange} value={this.state.values.wholesalerId}>
                                <option value={-1}>Select A Wholesaler</option>
                                {this.state.wholesalerList && this.state.wholesalerList.map(wh => <option key={`wh-${wh.id}`} value={wh.id}>{wh.name}</option>)}
                            </select>
                        </div>
                        <div className="input-field col s12 m3">
                            <input id="dateOfReceipt" type="date" onChange={this.onChange} value={this.state.values.dateOfReceipt} />
                            <label htmlFor="dateOfReceipt">Date Recieved</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <textarea id="notes" className="materialize-textarea" onChange={this.onChange} value={this.state.values.notes} />
                            <label htmlFor="notes">Notes</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small red white-text waves-effect waves-dark col s12" onClick={this.uploadCancel}>Cancel</a>
                        </div>
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small blue white-text waves-effect waves-dark col s12" onClick={this.uploadFile}>Upload</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(WholesalerCreditReport);